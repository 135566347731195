import React from "react";

import ContactForm from "../../../../ContactForm";

const FutureFuels = ({ name = "future-fuels" }) => {
    return (
        <ContactForm
            urlEnding="future-fuels"
            pushToGTMString={`${name}:contact-form`}
            canSendStorageString={`${name}::contact-form:sent-at`}
            emailHeadline="futurefuels@hoyer.de"
        />
    );
};

export default FutureFuels;
